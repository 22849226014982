import 'bulma/css/bulma.min.css';
import { FaFacebook } from 'react-icons/fa';

function Header() {
    return (
  
        <header className="hero is-info">
            
      <div className="hero-body">
        <h1 className="title" style={{ color: '#003366' }}>
          Bollywood Glimpse Dance Academy
        </h1>
        <div className="social-icons">
          <a href="https://www.facebook.com/BollywoodGlimpse/" target="_blank" rel="noopener noreferrer">
            <FaFacebook size={30} /> {/* Adjust size as needed */}
          </a>
        </div>
      </div>
    </header>
     
    );
  }
  
  export default Header;


  


