import 'bulma/css/bulma.min.css';
function Home() {
    return (
        <main className="section has-background-black is-size-3">
        <div className="container">
          <p>Welcome to Bollywood Glimpse Dance Academy</p>
        </div>
      </main>
    );
  }
  
  export default Home;