import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using React Router for navigation
import './menu.css';

const MenuBar = () => {
  return (
    <nav className="menu-bar">
      <ul>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/register">Enroll</Link></li>
        <li><Link to="/faq">FAQ</Link></li>
      </ul>
    </nav>
  );
};

export default MenuBar;
