import 'bulma/css/bulma.min.css';
import React from 'react';

const FAQ = () => {
  return (
    <main className="section has-background-black is-size-3">
    <div className="container">
      <p>FAQs</p>
    </div>
  </main>
  );
};

export default FAQ;
