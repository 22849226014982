import 'bulma/css/bulma.min.css';
import React from 'react';

const Register = () => {
  return (
    <main className="section has-background-black is-size-3">
    <div className="container">
      <p>Enrollment</p>
    </div>
  </main>
  );
};

export default Register;