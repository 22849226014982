import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import FAQ from './FAQ'; // New FAQ component
import Header from './Header';
import Home from './Home';
import MenuBar from './MenuBar';
import Register from './Register';

const App = () => {
  return (
    <Router>
      <Header />
      <MenuBar />
      <Routes>
        {/* Define the default route (Home) */}
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Register />} />

        {/* Define the FAQ route */}
        <Route path="/faq" element={<FAQ />} />
      </Routes>
    </Router>
  );
};

export default App;
